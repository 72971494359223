import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  public static readonly fiveMinutes: 300000 = 300000;
  public static readonly TOKEN: 'token' = 'token';
  public static readonly EXPIRE_AT: 'expireAt' = 'expireAt';
  public static readonly DATA_NODE_COMPLETE_PATH: 'datanode' = 'datanode';
  public static readonly MASTER_NODE_COMPLETE_PATH: 'masternode' = 'masternode';
  public static readonly SYSTEM: 'system' = 'system';

  private static readonly EXPIRES: 'expires' = 'expires';
  private static readonly PATH: 'path' = 'path';
  private static readonly SECURE: 'Secure' = 'Secure';
  private static readonly SameSite: 'SameSite' = 'SameSite';

  setAuthCookie(token: string) {
    const expires: number = Date.now() + CookieService.fiveMinutes;
    this.setCookie(CookieService.TOKEN, token);
    this.setCookie(CookieService.EXPIRE_AT, new Date(expires).toUTCString());
  }

  setCookie(name: string, value: string, validTill?: Date) {
    if (validTill == undefined) {
      validTill = new Date(Date.now() + CookieService.fiveMinutes * 12 * 24);
    }
    this.deleteCookie(name);
    document.cookie = this.addDefaults(`${name}=${encodeURIComponent(value)};${CookieService.EXPIRES}=${validTill.toUTCString()}`);
  }

  getCookie(name: string): string | null {
    const match = document.cookie
      .split('; ')
      .find(row => row.startsWith(`${name}=`));
    return match ? decodeURIComponent(match.split('=')[1]) : null;
  }

  deleteCookie(name: string) {
    document.cookie = this.addDefaults(`${name}=;${CookieService.EXPIRES}=Thu, 01 Jan 1970 00:00:00 UTC;`);
  }

  private addDefaults(cookie: string): string {
    if (!cookie.endsWith(';')) {
      cookie = `${cookie};`
    }
    return `${cookie}${CookieService.PATH}=/;${CookieService.SECURE};${CookieService.SameSite}=Strict`;
  }
}
