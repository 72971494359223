import {Injectable} from '@angular/core';
import {CESService} from "../ces.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {AuthenticationService} from "../auth-service/auth.service";
import {ModalContainerContent} from "../../../shared/notification/modal/modal-container.class";
import {ModalService} from "../../../shared/notification/modal/modal.service";
import {CookieService} from "../browser-helper-service/cookie.service";
import {NodeService} from "../api-service/sub-services/node.service";
import {Logger} from "../../../shared/util/logger";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService extends CESService {
  private modalOpened: boolean = false;
  private running: boolean = false;

  constructor(router: Router,
              httpClient: HttpClient,
              notification: ToastService,
              private nodeService: NodeService,
              private modalService: ModalService,
              private authenticationService: AuthenticationService,
              private cookieService: CookieService) {
    super(router, httpClient, notification);
  }

  loginChecker() {
    if (this.running) {
      return;
    }
    this.running = true;
    this.loginRecursiveUpdater(true);
  }

  private async loginRecursiveUpdater(recursion: boolean) {
    let tokenExpires: number = Date.parse('Thu, 01 Jan 1970 00:00:00 UTC');
    const expires: string | null = this.cookieService.getCookie(CookieService.EXPIRE_AT);
    if (expires) {
      tokenExpires = Date.parse(expires);
    }
    const tokenWontExpireSoon: boolean = tokenExpires > (Date.now() + (CookieService.fiveMinutes / 5));
    if (tokenWontExpireSoon || this.getToken() == null) {
      Logger.info(`Token wont expire soon or token is not set: ${this.getToken()}`);
    } else if (this.getToken() != null){
      Logger.info(`Token will be refreshed: ${this.getToken()}`);
      await firstValueFrom(await this.authenticationService.refreshToken());
    }
    this.running = false;
    setTimeout((): void => recursion ? this.loginChecker() : undefined, 5000);
  }

  getToken(): string | null {
    return this.cookieService.getCookie(CookieService.TOKEN);
  }

  async reLogin(): Promise<boolean> {
    if (this.modalOpened) {
      return false;
    }
    this.modalOpened = true;
    let modal: ModalContainerContent = new ModalContainerContent(
      'NOTIFICATION.ALERT.SESSION.TITLE',
      '',
      undefined,
      undefined,
      'NOTIFICATION.ALERT.BUTTON.LOGIN',
      'NOTIFICATION.ALERT.BUTTON.LOGOUT',
      'btn-outline-light',
      'btn-outline-danger',
      'refreshToken');

    const result = await this.modalService.openModal(modal);
    if (typeof result === 'string') {
      if (!await this.authenticationService.relogin(result as string)) {
        this.modalOpened = false;
        return this.reLogin();
      } else {
        // Dirty fix for ENTRY-1018
        //window.location.reload();
        this.modalOpened = false;
        return true;
      }
    } else {
      this.modalOpened = false;
      this.authenticationService.logout().finally(() => this.router.navigate(['/user/login']).catch());
      return false;
    }
  }

}
