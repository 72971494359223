import {UserLoginResponseDto} from "./UserLoginResponseDto";

export class UserSessionInfoDto {
   changePassword: boolean;
   userUUID: string;
   roleId: number;
   clientName: string;
   username: string;
   hasProfileImage: boolean;
   systemIdentifier: any; // Uint
   onlineAvailable: boolean;

   constructor(changePassword: boolean,
               userUuid: string,
               roleId: number,
               clientName: string,
               username: string,
               hasProfileImage: boolean,
               systemIdentifier: any,
               onlineAvailable: boolean) {
     this.changePassword = changePassword;
     this.userUUID = userUuid;
     this.clientName = clientName;
     this.username = username;
     this.hasProfileImage = hasProfileImage;
     this.systemIdentifier = systemIdentifier;
     this.onlineAvailable = onlineAvailable;
     this.roleId = roleId;
   }

   updateClientName(clientName: string) {
     this.clientName = clientName;
   }

   static FROM_UserLoginResponseDto(result: UserLoginResponseDto): UserSessionInfoDto {
     return new UserSessionInfoDto(
       result.changePassword,
       result.userUuid || '',
       result.roleId,
       'webclient',
       '',
       false,
       0,
       false
     );
   }
}
