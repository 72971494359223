import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';

import {ItemAction, ItemSplittAction, ListAction, MultiListAction, MultiListSplittAction} from "../models/Actions";
import {ListActionEvent} from "../models/ListActionEvent";
import {ItemActionEvent, ItemReturnEvent} from "../models/ItemActionEvent";
import {ItemListFilter, ItemListItem} from "./ItemListItem";
import {MultiListActionEvent} from "../models/MultiListActionEvent";
import {Subscription} from "rxjs";
import {ApiService} from "../../core/services/api-service/api.service";
import {ItemManager} from "./ItemManager";
import {ModalService} from "../notification/modal/modal.service";
import {ModalContainerContent} from "../notification/modal/modal-container.class";
import {MultiListSplittActionEvent} from "../models/MultiListSplittActionEvent";
import {values} from "lodash";

@Component({
    selector: 'app-item-list',
    templateUrl: './item-list.component.html',
    styleUrls: ['./item-list.component.scss'],
    standalone: false
})

export class ItemListComponent<T> implements OnInit {
  protected _hideEditHeaders: boolean = false;
  @Input() set hideEditHeaders(hide: boolean) {
    this._hideEditHeaders = hide;
  }

  debug(e: any){
    console.log(e)
  }

  @Input() disableButtons: boolean = false;
  @Input() maxItemSelectedDevices: number = -1;

  @Input() skeletonShowingAfterMS: number = 200;
  @Input() prefixTitleTranslation: string = '';

  // Necessary inputs
  @Input() items!: ItemManager<T>

  @Input() enableChangesWarning: boolean = false
  @Input() enableReloadItems: boolean = false
  @Input() multiSelectActions: MultiListAction[] = []
  @Input() multiSelectSplitActions: MultiListSplittAction | undefined = undefined;

  private _showLoading: boolean = false;
  @Input() set showLoading(loading: boolean) {
    this._showLoading = loading;
    if (!loading && this.itemList.size == 0) {
      this._displayedItemList = [];
    }
  }

  get showLoading() {
    return this._showLoading
  }

  // Events
  @Output() onListAction = new EventEmitter<ListActionEvent<T>>()
  @Output() onMultiListAction = new EventEmitter<MultiListActionEvent<T>>()
  @Output() onMultiSelectSplitActions = new EventEmitter<MultiListSplittActionEvent<T>>()
  @Output() onItemAction = new EventEmitter<ItemActionEvent<T>>()
  @Output() onItemReturn = new EventEmitter<ItemReturnEvent<T>>()

  // Editor View Type (Logs)
  @Input() viewTypeModuleId?: string

  // ItemSelection and Event
  @Input() itemSelected = false
  @Input() headerName: string | undefined = undefined
  @Output() itemSelectedChange = new EventEmitter<boolean>()

  @Input() readonlyIds?: string[]

  // Filter items
  private _listFilter?: ItemListFilter<T>
  private subscription?: Subscription

  @Input() set listFilter(filter: ItemListFilter<T>) {
    this.subscription?.unsubscribe()
    this._listFilter = filter
    this.subscription = this._listFilter.onUpdate.subscribe(() => {
      this.updateFilteredItemList()
    })
  }

  // Custom Elements
  @Input() footerTemplate?: TemplateRef<HTMLElement>
  @Input() lightContainer = false
  @Input() messageNoContent?: string = "ITEM_LIST.EMPTY"

  // Fields
  selectedListItem?: ItemListItem<T>
  private itemList: Map<string, ItemListItem<T>> = new Map<string, ItemListItem<T>>()
  private _displayedItemList: [string, ItemListItem<T>][] = [];
  get displayedItemList(): [string, ItemListItem<T>][] {
    return this._displayedItemList;
  }
  itemActions?: ItemAction[]

  updateFilteredItemList() {
    let entries = [...this.itemList.entries()];
    if (entries.length > 0) {
      if (this._listFilter == null) {
        this._displayedItemList = entries;
        return
      }
      this._displayedItemList = entries.filter((entry) => this._listFilter!.filterListItem(entry[1].item, entry[1]));
    } else {
      this._displayedItemList = [];
    }
  }

  // Values
  lastScrollY = 0

  // Multiselect
  multiSelectItems: Set<string> = new Set<string>()

  constructor(
    private alertService: ModalService,
    private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.storeScrollposition() // init
    this.subscription = this.items?.onChanges.subscribe(next => {
      this.onItemUpdate(next)
    })
  }

  onItemUpdate(items: Map<string, ItemListItem<T>>) {
    this.storeScrollposition()

    this.itemList = items

    //check removed items in selection
    for (let entry of this.multiSelectItems) {
      if (!this.itemList.get(entry)) {
        this.multiSelectItems.delete(entry)
      }
    }

    this.updateFilteredItemList()

    setTimeout(() => { // fix for page scrolling to bottom
      document.documentElement.scrollTop = this.lastScrollY
    }, 10)
  }

  onSelectChange(event: any, listItem: ItemListItem<T>) {
    if (event?.currentTarget?.checked || event == true) {
      this.multiSelectItems.add(listItem.uuid)
    } else {
      this.multiSelectItems.delete(listItem.uuid)
    }
  }

  onSelectAllChange(event: any) {
    if (event?.currentTarget?.checked) {
      this.itemList.forEach(item => {
        if (!item.readonly) {
          this.multiSelectItems.add(item.uuid);
        }
      })
    } else {
      this.multiSelectItems.clear()
    }
  }

  private storeScrollposition() {
    this.lastScrollY = document.documentElement.scrollTop
  }

  private restoreScrollposition(scrollTo = this.lastScrollY) {
    // scroll to last position with 450ms interpolation
    for (let i = 1; i <= 15; i++) {
      setTimeout(() => {
        document.documentElement.scrollTop = scrollTo * (i / 15)
      }, i * 30)
    }
  }

  /** Manually set selected list item (e.g. new item)
   *
   * @param listItem
   * @param itemActions
   */
  setSelectedListItem(listItem: ItemListItem<T>, ...itemActions: ItemAction[]) {
    this.selectedListItem = listItem
    this.itemSelected = true
    this.itemSelectedChange.emit(true)
    this.itemActions = itemActions
  }

  onClickMainListAction(editListItem: ItemListItem<T>) {
    if (editListItem.actions.length != 0) {
      this.onClickListAction(editListItem, editListItem.actions[0])
    }
  }

  onClickListAction(editListItem: ItemListItem<T>, action: ListAction) {

    const listActionEvent = new ListActionEvent<T>(action, editListItem.item, editListItem)
    this.onListAction.emit(listActionEvent)

    if (action.selectAction) {
      this.storeScrollposition()
      this.restoreScrollposition(0) // scroll to top

      this.itemActions = listActionEvent.itemActions
      this.handleSelectListItem(editListItem)

      if (this.viewTypeModuleId != null) {
        this.apiService.logbook.sendEditorOpenedLog(this.viewTypeModuleId, editListItem.name)
      }

    } else {
      this.handleSelectListItem(undefined)
    }

    if (!action.callback) {
      return
    }

    try {
      action.callback()
    } catch (err) {
      console.log(err)
    }
  }

  private handleSelectListItem(editListItem: ItemListItem<T> | undefined) {
    this.selectedListItem = editListItem
    const itemSelected = (editListItem != undefined)
    this.itemSelected = itemSelected
    this.itemSelectedChange.emit(itemSelected)
  }

  onClickMultiSplitAction(action: ItemSplittAction) {
    this.onMultiSelectSplitActions.emit(
      new MultiListSplittActionEvent<T>(
        action,
        Array.from(this.itemList.values()).reduce<T[]>((result, entry) => {
          if (this.multiSelectItems.has(entry.uuid)) {
            result.push(entry.item)
          }
          return result;
        }, [])
      )
    )

    this.multiSelectItems.clear()
  }

  onClickMultiAction(action: MultiListAction) {
    this.onMultiListAction.emit(
      new MultiListActionEvent<T>(
        action,
        Array.from(this.itemList.values()).reduce<T[]>((result, entry) => {
          if (this.multiSelectItems.has(entry.uuid)) {
            result.push(entry.item)
          }
          return result;
        }, [])
      )
    )

    this.multiSelectItems.clear()
  }

  onClickItemAction(action: ItemAction) {

    this.onItemAction.emit(new ItemActionEvent<T>(action, this.selectedListItem!.item, () => {
      this.doReturn()
    }))

    if (action.returnAction) {
      this.doReturn()
    }

    try {
      if (action.callback) {
        action.callback()
      }
    } catch (err) {
      console.log(err)
    }
  }

  onClickReturn() {

    if (this.enableChangesWarning) {
      this.alertService.openModal(new ModalContainerContent(
        'ITEM_LIST.ALERT.CHANGES.TITLE',
        'ITEM_LIST.ALERT.CHANGES.TEXT',
        () => {
          this.onItemReturn.emit(new ItemReturnEvent<T>(this.selectedListItem?.item));
          this.doReturn();
          return Promise.resolve(true);
        }
      ));
      return
    }

    this.onItemReturn.emit(new ItemReturnEvent<T>(this.selectedListItem?.item)) //Return
    this.doReturn()
  }

  private doReturn() {
    this.itemSelected = false
    this.itemSelectedChange.emit(false)
    this.selectedListItem = undefined

    this.restoreScrollposition()
  }

  itemBatteryOverlay(image: string): string | undefined {
    // This is a very hacky way, but the default .battery img was not overwritten - Workaround
    // See locking-device-icons.scss
    if (image != undefined) {
      if (this.isBlackDesign && image.includes('BatteryCondition') && !image.includes('-black.svg')) {
        return image.replace('.svg', '-black.svg')
      }
    }
    return image
  }

  get isBlackDesign(): boolean {
    const themeDesign = document.body.getAttribute("data-bs-theme");
    if (themeDesign != null) {
      return themeDesign.includes('black');
    }
    return false;
  }
}
