import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {AccessProfileDto} from "../../../../shared/entities/accessProfile/AccessProfileDto";
import {Observable} from "rxjs";
import {UpdateAccessProfileDto} from "../../../../shared/entities/accessProfile/UpdateAccessProfileDto";
import {
  AccessProfileAssignableDevicesAndGroups
} from "../../../../shared/entities/accessProfile/AccessProfileAssignableDevicesAndGroupsDto";
import {AccessProfileDetailDto} from "../../../../shared/entities/accessProfile/AccessProfileDetailDto";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {NodeService} from "./node.service";

export class AccessProfileService extends CESService {

  constructor(router: Router,
              httpClient: HttpClient,
              notification: ToastService,
              private nodeService: NodeService) {
    super(router, httpClient, notification);
  }

  // default CRUD
  async getAll(): Promise<Observable<AccessProfileDto[]>> {
    return this.httpClient.get<AccessProfileDto[]>(await this.nodeService.buildUrl(undefined, '/accessProfiles'));
  }

  async get(uuid: string): Promise<Observable<AccessProfileDetailDto>> {
    return this.httpClient.get<AccessProfileDetailDto>(await this.nodeService.buildUrl(undefined, `/accessProfile/${uuid}`));
  }

  async addAccessProfile(accessProfile: UpdateAccessProfileDto): Promise<Observable<UpdateAccessProfileDto>> {
    return this.httpClient.post<UpdateAccessProfileDto>(await this.nodeService.buildUrl(undefined, '/accessProfile'), accessProfile);
  }

  async updateAccessProfile(accessProfile: UpdateAccessProfileDto): Promise<Observable<UpdateAccessProfileDto>> {
    return this.httpClient.put<UpdateAccessProfileDto>(await this.nodeService.buildUrl(undefined, `/accessProfile/${accessProfile.uuid}`), accessProfile);
  }

  async delete(accessProfileUuid: string, silent: boolean = false): Promise<Observable<HttpResponse<any>>> {
    return this.httpClient.delete<any>(await this.nodeService.buildUrl(undefined, `/accessProfile/${accessProfileUuid}`), {observe: 'response'});
  }

  async getAllAssignableLockingDevicesAndGroups(accessProfileUuid: string | undefined): Promise<Observable<AccessProfileAssignableDevicesAndGroups>> {
    const path: string = accessProfileUuid == undefined || accessProfileUuid.length < 3 ? `/accessProfile/assignable` : `/accessProfile/${accessProfileUuid}/assignable`;
    return this.httpClient.get<AccessProfileAssignableDevicesAndGroups>(await this.nodeService.buildUrl(undefined, path));
  }

  getImageNameForAccessProfileType(typeId: number): string {
    switch (typeId) {
      case 1:
        return 'AccessProfileType-Denied';
      case 2:
        return 'AccessProfileType-Allowed';
      case 3:
        return 'AccessProfileType-AllowedOffice';
      default:
        return 'AccessProfileType-Denied';
    }
  }
}
