import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {LockingDeviceGroupDto} from "../../../../shared/entities/locking-device/LockingDeviceGroupDto";
import {LockingDeviceGroupDetailsDto} from "../../../../shared/entities/locking-device/LockingDeviceGroupDetailsDto";
import {
  LockingDeviceGroupAssignableDto
} from "../../../../shared/entities/locking-device/LockingDeviceGroupAssignableDto";
import {firstValueFrom, Observable} from "rxjs";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {NodeService} from "./node.service";

export class LockingDeviceGroupService extends CESService {

  private baseUrl: string = '';

  constructor(router: Router,
              httpClient: HttpClient,
              notification: ToastService,
              private nodeService: NodeService) {
    super(router, httpClient, notification);
  }

  private async getBaseUrl(path: string): Promise<string> {
    if (this.baseUrl.length == 0) {
      this.baseUrl = (await this.nodeService.buildUrl(undefined, '/group/devices'))!;
    }
    return `${this.baseUrl}${path}`;
  }

  // CRUD
  async getAll(): Promise<Observable<LockingDeviceGroupDto[]>> {
    return this.httpClient.get<LockingDeviceGroupDto[]>(await this.getBaseUrl(''));
  }

  async get(uuid: string): Promise<Observable<LockingDeviceGroupDetailsDto | undefined>> {
    return this.httpClient.get<LockingDeviceGroupDetailsDto | undefined>(await this.getBaseUrl(`/${uuid}`));
  }

  async add(lockingDeviceGroupDto: LockingDeviceGroupDto): Promise<Observable<LockingDeviceGroupDto | undefined>> {
    return this.httpClient.post<LockingDeviceGroupDto | undefined>(await this.getBaseUrl(''), lockingDeviceGroupDto);
  }

  async getAssignableDevicesForParams(): Promise<Observable<LockingDeviceGroupAssignableDto[]>> {
    return this.httpClient.get<LockingDeviceGroupAssignableDto[]>(await this.getBaseUrl('/assignable/devicesForParams'));
  }

  async getAssignableDevicesForAccess(uuid: string | undefined): Promise<Observable<LockingDeviceGroupAssignableDto[]>> {
    return uuid == undefined || uuid.length == 0 ?
      this.httpClient.get<LockingDeviceGroupAssignableDto[]>(await this.getBaseUrl('/assignable/devicesForAccess')) :
      this.httpClient.get<LockingDeviceGroupAssignableDto[]>(await this.getBaseUrl(`/${uuid}/assignable/devicesForAccess`));
  }

  async update(lockingDeviceGroupDto: LockingDeviceGroupDto): Promise<Observable<LockingDeviceGroupDto | undefined>> {
    return this.httpClient.put<LockingDeviceGroupDto | undefined>(await this.getBaseUrl(`/${lockingDeviceGroupDto.uuid}`), lockingDeviceGroupDto);
  }

  async delete(lockingDeviceGroupUuids: string[]): Promise<string[]> {
    let failed: string[] = [];
    for (const uuid of lockingDeviceGroupUuids) {
      if ((await firstValueFrom(this.httpClient.delete(await this.getBaseUrl(`/${uuid}`), {observe: 'response'}))).ok) {
        console.info(`Delete of ${uuid} successfully.`);
      } else {
        failed.push(uuid);
      }
    }
    return failed
  }

}
