import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {LockingDeviceParamsDto} from "../../../../shared/entities/locking-device/LockingDeviceParamsDto";
import {Observable} from "rxjs";
import {LockingDeviceDto} from "../../../../shared/entities/locking-device/LockingDeviceDto";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {NodeService} from "./node.service";

export class LockingDeviceService extends CESService {
  readonly MAX_ASSIGNMENTS_PARAMS_GROUPS: number = 1;
  readonly MAX_ASSIGNMENTS_ACCESS_GROUPS: number = 30;
  readonly MIN_PARAMS_OFFICE_TIMEOUT: number = 60;

  constructor(router: Router,
              httpClient: HttpClient,
              notification: ToastService,
              private nodeService: NodeService) {
    super(router, httpClient, notification);
  }

  async getAll(): Promise<Observable<LockingDeviceDto[]>> {
    return this.httpClient.get<LockingDeviceDto[]>(await this.nodeService.buildUrl(undefined, `/lockingDevices`));
  }

  async update(lockingDeviceDto: LockingDeviceDto): Promise<Observable<LockingDeviceDto>> {
    return this.httpClient.patch<LockingDeviceDto>(await this.nodeService.buildUrl(undefined, `/lockingDevices/${lockingDeviceDto.uuid}`), lockingDeviceDto);
  }

  getImageNameForDeviceType(typeId: number): string {
    switch (typeId) {
      case 1:
        return 'DeviceType-CYL';
      case 2:
      case 3:
        return 'DeviceType-WT';
      case 4:
        return 'DeviceType-LS';
      case 5:
        return 'DeviceType-SS';
      case 6:
        return 'DeviceType-FL';
      default:
        return 'DeviceType-CYL';
    }
  }

  getBatteryCondition(typeId: number): string {
    switch (typeId) {
      case 1:
        return `Critical`;
      case 2:
        return `Ok`;
      case 3:
        return `Good`;
      default:
        return 'Critical';
    }
  }

  shouldShowLengthForDeviceType(typeId: number) {
    return typeId != 2 && typeId != 3 && typeId != 5;
  }

  shouldShowHandleInfoForDeviceType(typeId: number) {
    return !(typeId != 5 && typeId != 6);
  }

  shouldShowImageForDeviceType(typeId: number): boolean {
    return typeId != 2 && typeId != 3;
  }

  isReadonly(): boolean {
    return super.accessIsReadOnly;
  }

  isProgrammingAllowed(): boolean {
    return super.canAdd;
  }

  // Parameters
  async getParameters(deviceUuid: string): Promise<Observable<LockingDeviceParamsDto | undefined>> {
    return this.httpClient.get<LockingDeviceParamsDto | undefined>(await this.nodeService.buildUrl(undefined, `/lockingDevices/${deviceUuid}/params`));
  }

  async updateParameters(deviceParams: LockingDeviceParamsDto): Promise<Observable<LockingDeviceParamsDto | undefined>> {
    return this.httpClient.put<LockingDeviceParamsDto>(await this.nodeService.buildUrl(undefined, `/lockingDevices/${deviceParams.uuid!}/params`), deviceParams as LockingDeviceParamsDto);
  }

  isOnlineAvailable(): boolean {
    return this.session?.onlineAvailable || false;
  }

}
