<div [loadingCes]="isLoading" id="content">
  <div class="modal-header border-bottom-0">
    <h4 class="modal-title center" id="modal-basic-title">
      {{ headerText | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
    <!-- Add / REMOVE DEVICE BUTTON -->
    <div class="dtw-cta" style="margin-top: 10px;">
        <div class="start-binding m-1">
            <button type="button"
                    [ngClass]="addRemoveButton.styleClasses"
                    data-testid="item-list-action-buttons"
                    style="width: 100%; height: 52px;"
                    (click)="addRemoveButton.callback()"
                    [disabled]="startButtonClicked || !pinInputEnabled">
                <i *ngIf="!startButtonEnabled" icon="io-start" style="padding-right: 8px;"></i>
                {{ addRemoveButton.key | translate }}
            </button>
        </div>
    </div>

    <div id="progress-container" class="progress-container">
      <h3 class="progress-headline">{{ "PROGRAMMING.MODAL.PROGRESS" | translate }}
      <i *ngIf="processAndPollingRunning" icon="io-loading" class="spin"></i></h3>
      <div class="progress">
        <ngb-progressbar *ngIf="commissionManager.errorIsPresent" class="w-100 mb-1 border-primary progress-red" [value]="1" [max]="1"></ngb-progressbar>
        <ngb-progressbar *ngIf="!commissionManager.errorIsPresent" class="w-100 mb-1 border-primary" [value]="currentOrderNumber" [max]="ioBridgeProcess.stateFinalize.order"></ngb-progressbar>
      </div>
      <div class="progress-steps">
        <ul style="padding-right: 2rem">
          <li class="center">{{ commissionManager.error | iob:commissionManager.state:{errorIsPresent:commissionManager.errorIsPresent,code:commissionManager.errorCode} }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer border-top-0">
    <button type="button" class="btn text-secondary btn-outline-danger" [hidden]="!commissionManager.errorIsPresent"
            (click)="onAbort()">{{ "PROGRAMMING.MODAL.BUTTON.CANCEL" | translate }}
    </button>
    <button type="button" class="btn text-secondary btn-outline-light"
            (click)="onClose(ModalCloseCause.CLOSED)">{{ buttonText | translate }}
    </button>
  </div>
</div>
  