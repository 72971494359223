import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {Observable} from "rxjs";
import {FirmwareGetStatusDto} from "../../../../shared/entities/firmware/FirmwareGetStatusDto";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {NodeService} from "./node.service";

export class FirmwareService extends CESService {

  constructor(router: Router,
              httpClient: HttpClient,
              notification: ToastService,
              private nodeService: NodeService) {
    super(router, httpClient, notification)
  }

  async getStatus(): Promise<Observable<FirmwareGetStatusDto[]>> {
    return this.httpClient.get<FirmwareGetStatusDto[]>(await this.nodeService.buildUrl(undefined, '/firmware/status'));
  }

  async triggerSync(): Promise<Observable<HttpResponse<any>>> {
    return this.httpClient.post<any>(await this.nodeService.buildUrl(undefined, '/firmware/sync'), '', {observe: 'response'});
  }
}
