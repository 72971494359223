export class NodeConfiguration {
  constructor(public scheme: string,
              public host: string,
              public path: string,
              public apiKey: string,
              public isDataNode: boolean) {
  }
}

export interface NodeConfigurationOld {
  endpointUrl: string
  endpointApiKey: string
  basePath: string
}

export function NodeConfigurationScheme(node: NodeConfiguration): NodeConfiguration {
  node.scheme = node.host.includes('localhost') ? 'http' : node.scheme;
  return node;
}

export function ToNodeConfiguration(node: NodeConfiguration | NodeConfigurationOld): NodeConfiguration {
  if (Object.hasOwnProperty.call(node, 'endpointUrl')) {
    node = {
      scheme: (node as NodeConfigurationOld).endpointUrl.startsWith('https') ? 'https' : 'http',
      host: (node as NodeConfigurationOld).endpointUrl.replace('https://', '').replace('http://', ''),
      apiKey: (node as NodeConfigurationOld).endpointApiKey,
      path: (node as NodeConfigurationOld).basePath,
      isDataNode: (node as NodeConfigurationOld).basePath.includes('CESAdmin')
    } as NodeConfiguration;
  }
  return NodeConfigurationScheme(node as NodeConfiguration);
}
