import {Component, Input, OnInit} from '@angular/core';
import {passwordComplexity, PasswordComplexity} from "../util/password-complexity";
import {environment} from "../../../environments/environment";
import {firstValueFrom} from "rxjs";
import {ApiService} from "../../core/services/api-service/api.service";


@Component({
    selector: 'app-password-complexity',
    templateUrl: './password-complexity.component.html',
    styleUrls: ['./password-complexity.component.scss'],
    standalone: false
})
export class PasswordComplexityComponent implements OnInit {

  passwordComplexityData: PasswordComplexity | undefined = undefined;

  @Input() value: string | undefined;
  @Input() showHeadline: boolean | undefined;

  constructor(private apiService: ApiService) {
  }

  async ngOnInit() {
    let clientPasswordComplexity: number = 2;
    if (this.apiService.nodeService.nodeConfiguration?.isDataNode == true) {
      clientPasswordComplexity = (await firstValueFrom(await this.apiService.client.getClient()))?.passwordComplexity || 2;
    }
    this.passwordComplexityData = passwordComplexity.find((p) => p.id === clientPasswordComplexity);
  }

  async complexityRegexPattern(): Promise<string> {
    if (this.passwordComplexityData == undefined) {
      await this.ngOnInit();
    }
    if (!environment.passwordComplexityEnabled) {
      return '';
    }
    return this.passwordComplexityData?.regEx || '';
  }

  replaceLengthInMessage(str: string): string {
    return str.replace('{{length}}', '' + (this.passwordComplexityData!.minLength || 10));
  }

  get passwordValidationLength(): boolean {
    return (this.value || '').length < this.passwordComplexityData!.minLength;
  }

  get passwordRegExAllCharacters(): boolean {
    return (this.value || '').match(this.passwordComplexityData!.regExPattern.regExAllCharacters) != null;
  }

  get passwordRegExSmallCharacters(): boolean {
    return (this.value || '').match(this.passwordComplexityData!.regExPattern.regExSmallCharacters) != null;
  }

  get passwordRegExBigCharacters(): boolean {
    return (this.value || '').match(this.passwordComplexityData!.regExPattern.regExBigCharacters) != null;
  }

  get passwordRegExExtraCharacters(): boolean {
    return (this.value || '').match(this.passwordComplexityData!.regExPattern.regExSpecialCharacters) != null;
  }

  get passwordRegExNumbers(): boolean {
    return (this.value || '').match(this.passwordComplexityData!.regExPattern.regExNumbersCharacters) != null;
  }

}
