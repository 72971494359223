import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import {LogbookRequestDto} from "../../../../shared/entities/Logbook/LogbookRequestDto";
import {LogbookDto} from "../../../../shared/entities/Logbook/LogbookDto";
import {LogbookResponseDto} from "../../../../shared/entities/Logbook/LogbookResponseDto";
import {LogbookDetailDto} from "../../../../shared/entities/Logbook/LogbookDetailDto";
import {firstValueFrom, Observable, take} from "rxjs";
import {LogbookAddDto} from "../../../../shared/entities/Logbook/LogbookAddDto";
import {EVENT_TYPE} from "../../../../shared/lookup/event.lookup";
import {LOGBOOK_TRANSLATION_KEY} from "../../../../shared/lookup/logbook.lookup";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {NodeService} from "./node.service";

const maximumItemsPdf = 5000;

export class LogbookService extends CESService {

  private readonly styles = {
    title: {
      fontFamily: "'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif",
      align: "start",
      marginLeft: 30,
      marginRight: 30,
      fontSize: 16,
      marginBottom: 60,
      color: "#005DA0"
    },
    sub_title: {
      fontFamily: "'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif",
      marginLeft: 15,
      fontSize: 14,
    },
    date: {
      fontFamily: "'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif",
      marginLeft: 15,
      fontSize: 12,
      width: "100%"
    },
    contentTxt: {
      fontFamily: "'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif",
      fontSize: 12,
      marginLeft: 15,
      marginBottom: 15,
      width: "100%"
    }
  }

  /** Timestamp of first item (prevent loading newer ones with each request) */
  private requestTimestamp = 0;
  private skip = 0;

  private completelyLoaded = false;

  itemsPerRequest: number = 50;

  private items: LogbookDto[] = [];

  constructor(router: Router,
              httpClient: HttpClient,
              notification: ToastService,
              private nodeService: NodeService) {
    super(router, httpClient, notification)
    this.resetRequest();
  }

  // items request
  resetRequest() {
    this.requestTimestamp = 0
    this.skip = 0

    this.completelyLoaded = false

    this.items = []
  }

  getItems(): LogbookDto[] {
    return this.items
  }

  /** Load content of next page (defined by `itemsPerRequest`).
   * Returns all loaded items.
   *
   * @returns items
   */
  async loadNext(): Promise<LogbookDto[]> {
    if (this.isCompletelyLoaded) {
      return this.items
    }

    const requestDto = new LogbookRequestDto(this.requestTimestamp, this.skip, this.itemsPerRequest, "", undefined);
    const responseDto = await this.loadLogbookItems(requestDto);

    this.completelyLoaded = responseDto?.completelyLoaded || false
    this.items.push(...responseDto?.entries || []);

    // update timestamp and skip
    if (this.requestTimestamp == 0 && this.items.length != 0) { // set request timestamp of first returned item
      this.requestTimestamp = this.items[0].timestamp;
    }

    this.skip += requestDto.top

    return this.items
  }

  get isCompletelyLoaded(): boolean {
    return this.completelyLoaded
  }

  async loadLogbookItems(logbookRequestDto: LogbookRequestDto): Promise<LogbookResponseDto | null | undefined> {
    const url: string = await this.nodeService.buildUrl(undefined, `/logbook`);
    if (url == '') {
      return undefined;
    }
    let params: HttpParams = new HttpParams();
    params = params.append('timestamp', logbookRequestDto.timestamp);
    params = params.append('skip', logbookRequestDto.skip);
    params = params.append('top', logbookRequestDto.top);
    const logbooks: LogbookResponseDto = await firstValueFrom(this.httpClient.get<LogbookResponseDto>(url, {params: params}));
    for (const entry of logbooks?.entries || []) {
      entry.title = EVENT_TYPE.filter(value => value.id == entry.eventTypeId)[0].value.toUpperCase();
    }
    return logbooks
  }

  async loadDetails(uuid: string): Promise<Observable<LogbookDetailDto[]>> {
    return this.httpClient.get<LogbookDetailDto[]>(await this.nodeService.buildUrl(undefined, `/logbook/${uuid}`));
  }

  async deleteEntries(entryIds: string[]): Promise<LogbookDto[]> {
    const url: string = await this.nodeService.buildUrl(undefined, `/logbook/deleteMultiple`);
    if (url == '') {
      return this.items;
    }
    if (!(await this.httpClient.post<any>(url, entryIds, {observe: 'response'}).toPromise())?.ok) {
      return this.items;
    }

    // refresh view
    this.items = this.items.filter(item => {
      return entryIds.indexOf(item.uuid) == -1;
    })

    // load new entries if empty
    if (this.items.length < this.itemsPerRequest) {
      this.items = await this.loadNext()
    }

    this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.DELETE')

    return this.items
  }

  async sendEditorOpenedLog(moduleId: string, objectId: string) {
    const body: LogbookAddDto = new LogbookAddDto(
      1002,
      1002,
      Math.floor(Date.now() / 1000),
      [new LogbookDetailDto(1000, moduleId, 'module'),
        new LogbookDetailDto(1003, objectId)]
    );

    this.httpClient.post<any>(await this.nodeService.buildUrl(undefined, `/logbook`), body).pipe(take(1)).subscribe({
      complete: () => { }
    });
  }

  // pdf creation
  async createPdfContent(translate: TranslateService) {
    try {
      const result = await this.loadLogbookItems(
        new LogbookRequestDto(0, 0, maximumItemsPdf, "", undefined));

      let body = Array.of({
        text: `${translate.instant('LOGBOOK.PDF.FILENAME')} ${new Date().toLocaleTimeString('de',
          {day: '2-digit', year: 'numeric', month: '2-digit', hour: '2-digit', minute: '2-digit'})}`, style: 'title'
      });
      body.push(...result!.entries.flatMap(value => ([
        {
          text: function (): string {
            if (value.title == undefined) {
              return translate.instant('LOGBOOK.DETAILS.DATA_UNKNOWN');
            }
            return translate.instant([...EVENT_TYPE.filter(event => event.value.toUpperCase() == value.title!.toUpperCase())
              .map(event => `LOGBOOK.EVENT_TYPE.${event.value.toUpperCase().replaceAll('EVENT_', '')}`), 'LOGBOOK.DETAILS.DATA_UNKNOWN'][0]);
          }(), style: 'sub_title'
        },
        {
          text: `${new Date(value.timestamp * 1000).toLocaleTimeString('de', {
            day: '2-digit',
            year: 'numeric',
            month: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          })} UTC`, style: 'date'
        },
        {
          text: function (): string {
            if (value.logbookSummary == undefined) {
              return translate.instant('LOGBOOK.DETAILS.DATA_UNKNOWN');
            }
            let summary = value.logbookSummary.split(' ');
            summary.forEach((sum, index) => {
              const translationKeys: string[] = LOGBOOK_TRANSLATION_KEY.filter(key => key.name == sum).map(key => `${translate.instant(key.translationKey)}`);
              if (translationKeys.length > 0) {
                summary[index] = translationKeys[0];
              }
            });
            return summary.join(' ');
          }(), style: 'contentTxt'
        },
      ])));

      pdfMake.createPdf({
        content: body,
        styles: this.styles,
      }, {}, {}, pdfFonts.vfs).download(`${translate.instant('LOGBOOK.PDF.FILENAME')}-${new Date().toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })}.pdf`);
    } catch (err: any) {
      this.notification.showError(`NOTIFICATION.TOAST.CES_ERROR.${err.message}`);
    }
  }
}
