import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {SelectListItem} from "./SelectListItem";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-select-list',
    templateUrl: './select-list.component.html',
    styleUrls: ['./select-list.component.scss'],
    standalone: false
})
export class SelectListComponent implements OnInit, OnChanges {

  // Search
  searchForm!: FormGroup;
  searchText: string = '';

  // since component can be contained multiple times in DOM,
  // a uniqueId is needed to distinguish ids from other instances
  // e.g. select all checkbox
  static componentCount = 0
  uniqueId: number

  @ViewChild("itemSelector") itemSelector?: ElementRef

  @Input() maxAddableItems: number = -1;

  @Input() set readonly(readonly: boolean) {
    this.allowAdd = !readonly
    this.allowDelete = !readonly
  }

  @Input() allowAdd = false
  @Input() allowDelete = false

  @Input() noSelectedItemsMessage?: string
  @Input() noItemsMessage?: string
  @Input() addItemsTitle?: string

  selectedItems: Set<string> = new Set<string>();

  @Input() set selectedItemsInput(selectedItems: Set<string>) {
    this.selectedItems = new Set<string>(selectedItems);
    this.resetChangeDetection();
  }
  @Output() selectedItemsChange = new EventEmitter<Set<string>>()

  @Input() cssClassesAsString?: string
  @Input() readonlyIds?: string[]

  items = new Map<string, SelectListItem>()

  @Input() set allItems(allItems: SelectListItem[]) {
    if (allItems == null || allItems.length == 0) {
      this.items.clear()
    } else {
      // map all items to editListItem by mapping function
      this.items = new Map(allItems.map(value => [value.id, value]));
    }

    //remove all selections
    this.addSelection.clear()
    this.removeSelection.clear()
  }

  // Multiselect
  removeSelection: Set<string> = new Set<string>()

  // Add items
  addSelection: Set<string> = new Set<string>()

  // Change detection
  @Output() onChanges = new EventEmitter<boolean>()
  changed: boolean = false
  currentState?: string

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) {
    this.uniqueId = ++SelectListComponent.componentCount
  }

  ngOnInit(): void {
    this.resetChangeDetection();
    this.searchForm = this.formBuilder.group({
      searchText: ['', []]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItems'] != null) {
      this.resetChangeDetection();
    }
  }

  get accessReadonly() {
    return !this.allowAdd && !this.allowDelete
  }

  // Change detection
  resetChangeDetection() {
    this.currentState = JSON.stringify(Array.from(this.selectedItems))
    this.detectChanges()
  }

  private detectChanges() {
    const changed = this.currentState == null ?
      false :
      this.currentState != JSON.stringify(Array.from(this.selectedItems))

    if (this.changed != changed) {
      this.changed = changed
      this.onChanges.emit(this.changed)
    }
  }

  // Remove-selection
  onRemoveSelectionChange(event: any, key: string) {
    if (this.readonly) return;
    if (event?.currentTarget?.checked || event == true) {
      this.removeSelection.add(key)
    } else {
      this.removeSelection.delete(key)
    }
  }

  onRemoveSelectionChangeAll(event: any) {
    if (this.readonly) return;
    if (event?.currentTarget?.checked) {
      this.selectedItems.forEach(item => {
        this.removeSelection.add(item)
      })
    } else {
      this.removeSelection.clear()
    }
  }

  onRemoveSelected() {
    if (this.readonly) return;
    this.removeSelection.forEach((key => {
      this.selectedItems.delete(key)
    }))
    this.selectedItemsChange.emit(this.selectedItems)
    this.removeSelection.clear()

    this.detectChanges()
  }

  // Add-selection
  onAddSelectionChange(event: any, key: string) {
    if (this.readonly || (this.maxLimitReached && this.eventIsChecked(event))) return;
    if (this.eventIsChecked(event)) {
      this.addSelection.add(key)
    } else {
      this.addSelection.delete(key)
    }
  }

  private eventIsChecked(event: any): boolean {
    return event?.currentTarget?.checked || event == true;
  }

  onAddSelected() {
    if (this.readonly) return;
    this.addSelection.forEach((key => {
      this.selectedItems.add(key)
    }))
    this.selectedItemsChange.emit(this.selectedItems)
    this.addSelection.clear()

    this.detectChanges()
  }

  // Select Popup
  openItemSelector() {
    if (this.readonly) return;
    this.modalService.open(this.itemSelector, {centered: true, scrollable: true})
  }

  //set nofilter class img on black Design
  get extraCssClasses(): string {
    return this.cssClassesAsString != null ? this.cssClassesAsString : '';
  }

  get sortSelectedItems() {
    return new Set([...this.selectedItems].sort((first: string, second: string) =>
      this.sortLockMedia(this.items.get(first)!!, this.items.get(second)!!)))
  }

  get sortItems() {
    return new Map([...this.items.entries()]
      .sort((a: [string, SelectListItem], b: [string, SelectListItem]) =>
        this.sortLockingMediaMap(a, b)
      )
    );
  }

  sortLockingMediaMap(first: [string, SelectListItem], second: [string, SelectListItem]): number {
    return this.sortLockMedia(first[1], second[1])
  }

  sortLockMedia(first: any, second: any): number {
    try {
      const firstId = parseInt(first.name)
      const secondId = parseInt(second.name)
      if (Number.isNaN(firstId) || Number.isNaN(secondId)) {
        return 0
      }
      return firstId - secondId
    } catch (e) { }
    return 0
  }

  updateSearch(event?: any) {
    let search = this.searchForm.get("searchText")?.value || '';
    if (event instanceof InputEvent) {
      search = (<HTMLInputElement>event.target).value;
    }
    if (event.item != null) {
      search = event.item;
    }
    this.searchText = search;
    this.items = this.items;
  }

  resetSearch() {
    this.searchText = '';
    // @ts-ignore
    document.getElementById('search-bar-input').value = '';
    this.formBuilder = new FormBuilder();
    this.searchForm = this.formBuilder.group({
      searchText: ['', []]
    });
    this.items = this.items;
  }

  get maxLimitReached(): boolean {
    if (this.maxAddableItems === -1) {
      return false;
    }
    return this.maxAddableItems <= (this.addSelection.size + this.selectedItems.size);
  }
}
