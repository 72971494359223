import { Component } from '@angular/core';
import {NotificationTypes, ToastService} from './toast.service';

@Component({
    selector: 'app-notification-container',
    template: `
    <ngb-toast
      style="width: unset; max-width: unset;"
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [animation]="true"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)">
      <div class="form-floating-icon">
        @if (toast.type == NotificationTypes.DANGER) {
          <i icon="cross" style="font-size: 28px; color: rgba(0,0,0,0.33);"></i>
        }
        @if (toast.type == NotificationTypes.WARNING) {
          <i icon="info" style="font-size: 28px; color: rgba(0,0,0,0.33);"></i>
        }
        @if (toast.type == NotificationTypes.SUCCESS) {
          <i icon="check" style="font-size: 28px; color: rgba(0,0,0,0.33);"></i>
        }
      </div>
      <div style="flex-grow: 1; align-self: center; display: flex">
        <span style="flex-grow: 1;"></span>
        <span
          style="color: rgba(0, 0, 0, 0.33); font-size: 13px; padding-left: 10px; display: inline-block; font-weight: bold;">{{ toast.textOrTpl }}</span>
        <span style="flex-grow: 1;"></span>
      </div>
    </ngb-toast>
  `,
    host: {
        'class': 'toast-container position-fixed bottom-0 end-0 p-3',
        'style': 'display:flex; z-index: 3000; width: 280px; opacity: 1; inset: auto 10px 10px; margin: auto;'
    },
    standalone: false
})
export class ToastContainerComponent {
  constructor(public toastService: ToastService) {}

  protected readonly NotificationTypes = NotificationTypes;
}
