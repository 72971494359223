// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {masterNodeAPIKeys, masterNodeBasePath, masterNodeScheme, masterNodeUrls} from "./master-node.environment";
import {osFileExtensions} from "./file-extensions.environment";
import {featureFlags} from "./feature-flags.environment";

export const environment = {
  production: false,
  state: 'dev',
  ioBridgeUrl: 'https://127.0.0.1:8089',
  ioBridgeDownloadUrlWithoutType: 'https://firmware.ces-cloud.de/dev/cesentry-dtw-driver-install',
  useMock: false,
  masterNodeUrl: masterNodeUrls.develop,
  masterNodeKey: masterNodeAPIKeys.develop,
  masterNodeBasePath: masterNodeBasePath.develop,
  masterNodeScheme : masterNodeScheme.https,
  passwordComplexityEnabled: true,
  osFileExtensions,
  unleashUrl: 'https://flags.cesdev.eu/api/frontend',
  unleashToken: 'default:development.e0059c77d39e1bf086c6705e57693caf417cdcdabe22314fd77905a1',
  featureFlags
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
