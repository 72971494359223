import {EventEmitter, input} from "@angular/core";
import {Subscription} from "rxjs";
import {cloneDeep} from 'lodash';
import {FormatterService} from "../../../core/services/formatter-service/formatter.service";
import {map} from "rxjs/operators";

export class ChangeDetectorValue {
  private subscription?: Subscription;
  private _initialValue: any | null;
  private valueChange: EventEmitter<any> = new EventEmitter<any>();
  private validators?: Map<string, ((input: any) => (string | boolean))>;
  private touched: Map<string, string> = new Map<string, string>();
  private _allFieldsTouched: boolean = false;

  constructor(public _value: any | null,
              public changeCallback: (changed: boolean) => void = () => {},
              validators?: Map<string, ((input: any) => boolean)>) {
    if (_value == null) {
      this.initialValue = undefined;
    } else {
      this.initialValue = cloneDeep(_value);
    }
    this.validators = validators;
    this.subscription = this.valueChange.subscribe((val => {
      this.changeCallback(this.hasChanges)
    }));

    if (this._value && this._value.hasOwnProperty('valueChange')) {
      this._value.valueChange.subscribe((val: any) => {
        this.valueChange.emit(this.hasChanges);
      });
    }
  }

  private validatorStringToBoolean(input: string | boolean): boolean {
    if (typeof input === "boolean") {
      return input;
    }
    return input.length > 0;
  }

  get isValid(): boolean {
    let valid: boolean = true;
    this.validators?.forEach(validator => {
      if (!this.validatorStringToBoolean(validator(this.value))) {
        valid = false;
      }
    });
    return valid;
  }

  isSpecificValid(...ids: string[]): boolean {
    let valid: boolean = true;
    for (let i = 0; i < ids.length; i++) {
      if (this.validators?.has(ids[i]) && !this.validatorStringToBoolean(this.validators?.get(ids[i])!(this.value))) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  isSpecificGroupValid(...groupIds: string[]): boolean {
    let valid: boolean = true;
    for (let i: number = 0; i < groupIds.length; i++) {
      this.validators?.forEach((validator,key) => {
        if (key.includes(groupIds[i]) && !this.isSpecificValid(key)) {
          valid = false;
        }
      });
    }
    return valid;
  }

  hasSpecificChanges(fieldname: string): boolean {
    if (this._value && typeof this._value == 'object') {
      if (this._value.hasOwnProperty(fieldname)) {
        return this.initialValue == undefined ||
          !this.initialValue.hasOwnProperty(fieldname) ||
          this.toString(this._value[fieldname], fieldname) != this.toString(this.initialValue[fieldname], fieldname);
      }
    }
    if (fieldname.startsWith('_')) {
      return false;
    }
    return this.hasSpecificChanges(`_${fieldname}`);
  }

  private toString(value: any, key: string = ''): string {
    if (value === null || value === undefined) {
      return '';
    }
    switch (typeof value) {
      case "string":
        if (this.isEMail(value, key)) {
          return value.toLowerCase();
        }
        return value as string;
      case "boolean":
        return value as boolean ? 'true' : 'false';
      case "number":
        return `${value}`;
      case "bigint":
        return `${value}`;
      case "object":
        if (this.isFieldUnwanted(value)) {
          return '';
        }
        if (value instanceof Set) {
          value = Array.from(value).sort((a, b) => a.localeCompare(b));
        }
        return JSON.stringify(value);
      default:
        return '';
    }
  }

  get hasChanges(): boolean {
    if ((this.value == null && this.initialValue != null) || (this.value != null && this.initialValue == null)) {
      return true;
    }

    if (typeof this.initialValue == 'object' && typeof this.value == 'object') {
      for (let key in this.value) {
        if (this.initialValue.hasOwnProperty(key)) {
          if (this.toString(this.initialValue[key], key) != this.toString(this.value[key], key)) {
            const da = this.toString(this.initialValue[key], key)
            const db = this.toString(this.value[key], key)
            this.touched.set(key, db);
            return true;
          }
        } else {
          return true;
        }
      }
      if (this.value instanceof Set) {
        return (this.toString(this.initialValue) != this.toString(this.value))
      }
    }
    const debugPurpose1: string = this.toJsonString(this.initialValue);
    const debugPurpose2: string = this.toJsonString(this.value);
    return debugPurpose1 != debugPurpose2;
  }

  get value(): any | null {
    return this._value;
  }

  set value(value: any | null) {
    this._value = value;
    this.valueChange.emit(value == null ? true : value);
  }

  reset(value?: any | null, newChangeCallback?: (changed: boolean) => void) {
    if (value) {
      this._value = cloneDeep(value);
      this.initialValue = cloneDeep(value);
    } else {
      this.initialValue = cloneDeep(this._value);
    }
    this.touched = new Map<string, string>;
    if (newChangeCallback) {
      this.changeCallback = newChangeCallback;
    }
    this.valueChange = new EventEmitter<any>();
    this.subscription?.unsubscribe();
    this.subscription = this.valueChange.subscribe((() => {
      this.changeCallback(this.hasChanges)
    }));
  }

  static createNumberValidatorMinMaxRequired(name: string, min: number, max: number): Map<string, (input: any) => boolean> {
    return new Map<string, (input: any) => boolean>()
    .set(`${name}Empty`, (input: any) => {
        return ((input[`${name}`]) + '').replace(' ', '').length != 0;
    }).set(`${name}Required`, (input: any) => {
        return ((input[`${name}`]) + '').length != 0;
    }).set(`${name}Min`, (input: any) => {
        switch (typeof input[`${name}`]) {
        case 'number':
            return (input[`${name}`] as number) >= min;
        case 'string':
            return (input[`${name}`] + '').length >= min;
        }
        return input[`${name}`] >= min;
    }).set(`${name}Max`, (input: any) => {
        switch (typeof input[`${name}`]) {
        case 'number':
            return (input[`${name}`] as number) <= max;
        case 'string':
            return (input[`${name}`] + '').length <= max;
        }
        return input[`${name}`] <= max;
    });
  }

  static mergeValidatorMaps(first: Map<string, (input: any) => boolean>, ...maps: Map<string, (input: any) => boolean>[]): Map<string, (input: any) => boolean> {
    maps.forEach(map => {
      map.forEach((value, key) => {
        first.set(key, value);
      });
    });
    return first;
  }

  setAllFieldsTouched(){
    this._allFieldsTouched = true;
  }

  isFieldTouched(field: string): boolean {
    return this._allFieldsTouched || this.touched.has(field) || this.touched.has(`_${field}`);
  }

  get initialValue(): any {
    return this._initialValue;
  }

  set initialValue(value: any) {
    this._initialValue = value;
  }

  private toJsonString(value: any): string {
    return JSON.stringify(value, (key, value) => {
      if (this.isFieldUnwanted(value)) {
        return undefined;
      }
      if (this.isEMail(value, key)) {
        return value.toLowerCase();
      }
      return value || null;
    });
  }

  private isFieldUnwanted(field: any): boolean {
    if (field != null) {
      return field instanceof EventEmitter || field instanceof Subscription || field instanceof FormatterService;
    }
    return false;
  }

  private isEMail(value: any, key: any): boolean {
    return typeof key === 'string' && key.includes('mail') && typeof value === 'string' && value.includes('@');
  }
}

export interface ChangeDetectorModel {
  viewablePasswordFields: string[];
  valueChange: EventEmitter<any>;
  validators(): Map<string, (input: any) => boolean>;
  set values(values: any);
}
