import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ClientDto} from "../../../../shared/entities/client/ClientDto";
import {ClientRequestDto} from "../../../../shared/entities/client/ClientRequestDto";
import {firstValueFrom, Observable, Subject} from "rxjs";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {Injectable} from "@angular/core";
import {NodeConfiguration, NodeConfigurationOld} from "../../../interfaces/nodeConfiguration";
import {environment} from "../../../../../environments/environment";
import {Logger} from "../../../../shared/util/logger";
import {CookieService} from "../../browser-helper-service/cookie.service";

@Injectable({providedIn: 'root'})
export class NodeService extends CESService {
  private _nodeConfiguration: NodeConfiguration | undefined;

  get nodeConfiguration(): NodeConfiguration | undefined {
    return this._nodeConfiguration;
  }

  set nodeConfiguration(value: NodeConfiguration | undefined) {
    this._nodeConfiguration = value;
  }

  constructor(router: Router, httpClient: HttpClient, notification: ToastService) {
    super(router, httpClient, notification);
  }

  get http(): HttpClient {
    return this.httpClient;
  }

  async getMasterNodeJSON(): Promise<Observable<HttpResponse<NodeConfiguration | NodeConfigurationOld>>> {
    return this.httpClient.get<NodeConfiguration | NodeConfigurationOld>('settings/config.json', {observe: 'response'});
  }

  async requestDataNode(system: string): Promise<Observable<HttpResponse<NodeConfiguration>>> {
    return this.httpClient.get<NodeConfiguration>(`${environment.masterNodeScheme}://${environment.masterNodeUrl}${environment.masterNodeBasePath}system/${system}`, {observe: 'response'});
  }

  async nodeRequest(system: string): Promise<boolean> {
    const nodeConfig = await firstValueFrom(await this.requestDataNode(system));
    const body: NodeConfiguration | null | undefined = nodeConfig?.body;
    if (nodeConfig?.ok && body != null) {
      this.system = system;
      this.nodeConfiguration = new NodeConfiguration(body.scheme, body.host, body.path, body.apiKey, !body.path.includes("CESMaster"));
      this.schemaService.toLocalStorage(this.localStorageDataNode, this.nodeConfiguration);
      return true;
    }
    return false;
  }

  async buildUrl(system: string | undefined, path: string): Promise<string> {
    // Checking if system is available
    if (system == undefined && this.system == undefined) {
      Logger.error('System is not known');
      return '';
    } else if (system != undefined) {
      this.system = system;
    } else {
      system = this.system!;
    }

    // Checking if node configurations are available
    if (this.nodeConfiguration == undefined) {
      const storage = this.schemaService.fromLocalStorage(this.localStorageDataNode);
      if (storage.isValid) {
        this.nodeConfiguration = storage.data as NodeConfiguration;
      } else if ((!(await this.nodeRequest(system)))) {
        Logger.error('Nodeconfiguraion could not been retreived');
        return '';
      }
    }

    const basePath: string = `${this.nodeConfiguration!.scheme}://${this.nodeConfiguration!.host}${this.nodeConfiguration!.path}`;

    // Building url to datanode
    if (path.startsWith('/')) {
      return `${basePath}${path}`;
    }
    return `${basePath}/${path}`;
  }
}
