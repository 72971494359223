import {Injectable} from '@angular/core';
import {ClientService} from "./sub-services/client.service";
import {HealthService} from "./sub-services/health.service";
import {LockingMediaService} from "./sub-services/locking-media.service";
import {LogbookService} from "./sub-services/logbook.service";
import {LockingDeviceService} from "./sub-services/locking-device.service";
import {UserService} from "./sub-services/user.service";
import {AccessProfileService} from "./sub-services/access-profile.service";
import {TimeModelService} from "./sub-services/time-model.service";
import {JobService} from "./sub-services/job.service";
import {UserGroupService, UserGroupService as UserGroupServiceDefault} from "./sub-services/user-group.service";
import {
  LockingDeviceGroupService,
  LockingDeviceGroupService as LockingDeviceGroupServiceDefault
} from "./sub-services/locking-device-group.service";
import {Router} from "@angular/router";
import {FirmwareService} from "./sub-services/firmware.service";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "../auth-service/auth.service";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {CookieService} from "../browser-helper-service/cookie.service";
import {IoBridgeService} from "../io-bridge-service/io-bridge.service";
import {IoBridgeProcess} from "../../../shared/util/bindingStates";
import {RefreshTokenService} from "../refresh-token-service/refresh-token.service";
import {ModalService} from "../../../shared/notification/modal/modal.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CESService} from "../ces.service";
import {UserSessionInfoDto} from "../../../shared/entities/user/UserSessionInfoDto";
import {ClientDto} from "../../../shared/entities/client/ClientDto";
import {NodeService} from "./sub-services/node.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private router: Router;

  private readonly _authService: AuthenticationService;
  private readonly _clientService: ClientService;
  private readonly _firmwareService: FirmwareService;
  private readonly _healthService: HealthService;
  private readonly _userService: UserService;
  private readonly _userGroupService: UserGroupServiceDefault;
  private readonly _lockingDeviceService: LockingDeviceService;
  private readonly _lockingDeviceGroupService: LockingDeviceGroupServiceDefault;
  private readonly _lockingMediaService: LockingMediaService;
  private readonly _timeModelService: TimeModelService;
  private readonly _accessProfileService: AccessProfileService;
  private readonly _jobService: JobService;
  private readonly _logbookService: LogbookService;
  private readonly _ioBridgeService: IoBridgeService;
  private readonly _refreshTokenService: RefreshTokenService;

  private readonly _nodeService: NodeService;

  private readonly _ioBridgeProcess: IoBridgeProcess;
  private readonly _cookieService: CookieService;

  constructor(router: Router, httpClient: HttpClient, notification: ToastService) {
    this.router = router;
    this._cookieService = new CookieService();
    this._ioBridgeProcess = new IoBridgeProcess();

    this._nodeService = new NodeService(router, httpClient, notification);

    this._authService = new AuthenticationService(router, httpClient, notification, this._nodeService, this._cookieService);
    this._refreshTokenService = new RefreshTokenService(router, httpClient, notification, this._nodeService, new ModalService(new NgbModal()), this._authService, this._cookieService);
    this._clientService = new ClientService(router, httpClient, notification, this._nodeService);
    this._firmwareService = new FirmwareService(router, httpClient, notification, this._nodeService);
    this._healthService = new HealthService(router, httpClient, notification, this._nodeService);
    this._userService = new UserService(router, httpClient, notification, this._nodeService);
    this._userGroupService = new UserGroupServiceDefault(router, httpClient, notification, this._nodeService);
    this._lockingDeviceService = new LockingDeviceService(router, httpClient, notification, this._nodeService);
    this._lockingDeviceGroupService = new LockingDeviceGroupServiceDefault(router, httpClient, notification, this._nodeService);
    this._accessProfileService = new AccessProfileService(router, httpClient, notification, this._nodeService);
    this._timeModelService = new TimeModelService(router, httpClient, notification, this._nodeService);
    this._lockingMediaService = new LockingMediaService(router, httpClient, notification, this._nodeService);
    this._jobService = new JobService(router, httpClient, notification, this._nodeService);
    this._logbookService = new LogbookService(router, httpClient, notification, this._nodeService);
    this._ioBridgeService = new IoBridgeService(router, notification, httpClient, this._nodeService, this._ioBridgeProcess);

    // Try to restore session
    const expireAt: string | null = this._cookieService.getCookie(CookieService.EXPIRE_AT);
    const token: string | null = this._cookieService.getCookie(CookieService.TOKEN);
    const system: string | null = this._cookieService.getCookie(CookieService.SYSTEM);
    if (token && expireAt && system && Date.parse(expireAt) > Date.now()) {
      this._nodeService.nodeRequest(system).then(value => {
        if (value) {
          this.setSystem(system);
          this._clientService.getClient().then(() => {
            this._authService.refreshToken(true);
          });
        }
      });
    }
  }

  private restoreSession() {

  }

  async navigate(to: string) {
    await this.router.navigate([to]);
  }

  get auth(): AuthenticationService {
    return this._authService;
  }

  get client(): ClientService {
    return this._clientService;
  }

  get firmware(): FirmwareService {
    return this._firmwareService;
  }

  get health(): HealthService {
    return this._healthService;
  }

  get user(): UserService {
    return this._userService;
  }

  get userGroup(): UserGroupService {
    return this._userGroupService;
  }

  get lockingDevice(): LockingDeviceService {
    return this._lockingDeviceService;
  }

  get lockingDeviceGroup(): LockingDeviceGroupService {
    return this._lockingDeviceGroupService;
  }

  get lockingMedia(): LockingMediaService {
    return this._lockingMediaService;
  }

  get timeModel(): TimeModelService {
    return this._timeModelService;
  }

  get accessProfile(): AccessProfileService {
    return this._accessProfileService;
  }

  get job(): JobService {
    return this._jobService;
  }

  get logbook(): LogbookService {
    return this._logbookService;
  }

  get cookieService(): CookieService {
    return this._cookieService;
  }

  get iobService(): IoBridgeService {
    return this._ioBridgeService;
  }

  get tokenService(): RefreshTokenService {
    return this._refreshTokenService;
  }

  get nodeService(): NodeService {
    return this._nodeService;
  }

  get allServices(): CESService[] {
    return [this._authService, this._clientService, this._firmwareService,
      this._healthService, this._userService, this._userGroupService, this._lockingDeviceService,
      this._lockingDeviceGroupService, this._lockingMediaService, this._timeModelService, this._accessProfileService,
      this._jobService, this._logbookService, this._ioBridgeService, this._refreshTokenService];
  }

  setSession(session?: UserSessionInfoDto) {
    if (session != undefined) {
      this.allServices.forEach(service => {
        service.session = session;
      });
      return;
    }
    const services: CESService[] = this.allServices.filter(service => service.session != undefined);
    if (services.length != 0) {
      this.allServices.forEach(service => {
        service.session = services[0].session;
      });
    }
  }

  setClient(client?: ClientDto) {
    if (client != undefined) {
      this.allServices.forEach(service => {
        service.client = client;
      });
      return;
    }
    const services: CESService[] = this.allServices.filter(service => service.client != undefined);
    if (services.length != 0) {
      this.allServices.forEach(service => {
        service.client = services[0].client;
      });
    }
  }

  setSystem(system?: string) {
    if (system != undefined) {
      this.allServices.forEach(service => {
        service.system = system;
      });
      return;
    }
    const services: CESService[] = this.allServices.filter(service => service.system != undefined);
    if (services.length != 0) {
      this.allServices.forEach(service => {
        service.system = services[0].system;
      });
    }
  }

}
