import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {LockingMediaDto} from "../../../../shared/entities/LockingMediaDto";
import {Observable} from "rxjs";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {NodeService} from "./node.service";

export class LockingMediaService extends CESService {

  constructor(router: Router,
              httpClient: HttpClient,
              notification: ToastService,
              private nodeService: NodeService) {
    super(router, httpClient, notification);
  }

  async getAll(): Promise<Observable<LockingMediaDto[]>> {
    return this.httpClient.get<LockingMediaDto[]>(await this.nodeService.buildUrl(undefined, `/lockingMedia`));
  }

  async getAssignable(uuid: string = ''): Promise<Observable<LockingMediaDto[]>> {
    return this.httpClient.get<LockingMediaDto[]>(await this.nodeService.buildUrl(undefined, `/lockingMedia/assignable${uuid.length == 0 ? '' : `?uuid=${uuid}`}`));
  }

  async update(lockingMediaDto: LockingMediaDto): Promise<Observable<LockingMediaDto>> {
    return this.httpClient.patch<LockingMediaDto>(await this.nodeService.buildUrl(undefined, `/lockingMedia/${lockingMediaDto.uuid}`), lockingMediaDto);
  }

  async delete(mediaUuid: string): Promise<Observable<HttpResponse<any>>> {
    return this.httpClient.delete<any>(await this.nodeService.buildUrl(undefined, `/lockingMedia/${mediaUuid}`), {observe: 'response'});
  }

  getImageNameForMediaType(typeId: number): string {
    switch (typeId) {
      case 1:
        return 'MediaImplementation-ElectronicKey';
      case 2:
        return 'MediaImplementation-TransponderClip';
      case 3:
        return 'MediaImplementation-SlimPendant';
      case 4:
        return 'MediaImplementation-PremiumPendant';
      case 5:
        return 'MediaImplementation-Card';
      case 6:
        return 'MediaImplementation-Smartphone';
      default:
        return 'MediaImplementation-ElectronicKey';
    }
  }

  isReadonly(): boolean {
    return super.accessIsReadOnly;
  }

  canDeleteLockingMedia(lockingMedia: LockingMediaDto): boolean {
    return super.canDelete && lockingMedia.typeId == 4;
  }

  getIsArticleNumberVisible(lockingMedia: LockingMediaDto): boolean {
    return lockingMedia.articleNumber != undefined && lockingMedia.articleNumber.length > 0;
  }
}
