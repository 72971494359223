import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../shared/notification/toast/toast.service";
import {UserSessionInfoDto} from "../../shared/entities/user/UserSessionInfoDto";
import {ClientDto} from "../../shared/entities/client/ClientDto";
import {UserRoleType} from "../enums/userRoleType";
import {Injectable} from "@angular/core";
import {LocalStorageSchemaService} from "./browser-helper-service/local-storage-schema.service";

// Inject in root in order to be a singelton
@Injectable({
  providedIn: 'root',
})
export abstract class CESService {
  private _system: string | undefined;
  private _userImage: string | undefined;
  private _session: UserSessionInfoDto | undefined;
  private _client: ClientDto | undefined;

  protected schemaService: LocalStorageSchemaService;
  protected readonly localStorageDataNode: 'data-node-config' = 'data-node-config';

  protected constructor(
    protected router: Router,
    protected httpClient: HttpClient,
    protected notification: ToastService) {
    this.schemaService = new LocalStorageSchemaService();
  }

  get isLicenseValid(): boolean {
    if (!this.isLicenseBusiness) {
      return true;
    }
    const dateFromSecondsToMillis: number = this.client!.licenseExpireTimestamp! * 1000;
    return new Date(dateFromSecondsToMillis) > new Date();
  }

  private async navigateToPage(path: string) {
    await this.router.navigateByUrl(path);
  }

  // Helper
  get canAdd(): boolean {
    return this.isAdminOrHigher && this.isLicenseValid;
  }

  get canChange(): boolean {
    return this.isAdminOrHigher && this.isLicenseValid;
  }

  get canDelete(): boolean {
    return this.isAdminOrHigher && this.isLicenseValid;
  }

  get accessIsReadOnly(): boolean {
    return !this.isAdminOrHigher || !this.isLicenseValid;
  }


  get session(): UserSessionInfoDto | undefined {
    return this._session;
  }

  set session(value: UserSessionInfoDto | undefined) {
    this._session = value;
  }

  get system(): string | undefined {
    return this._system;
  }

  set system(value: string | undefined) {
    this._system = value;
  }

  get client(): ClientDto | undefined {
    return this._client;
  }

  set client(value: ClientDto | undefined) {
    this._client = value;
  }

  get userImage(): string | undefined {
    return this._userImage;
  }

  set userImage(value: string | undefined) {
    this._userImage = value;
  }

  get isAdminOrHigher(): boolean {
    switch (this.session?.roleId || UserRoleType.UNKNOWN) {
      case UserRoleType.PTI:
      case UserRoleType.OWNER:
      case UserRoleType.ADMIN:
        return true;
      default:
        return false;
    }
  }

  get isLicenseBusiness(): boolean {
    if (this.client == undefined || this.client?.licenseExpireTimestamp == undefined) {
      return false;
    }
    return (this.client?.licenseTypeId || 1) > 1;
  }
}
