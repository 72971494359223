import {Component, ElementRef, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalContainerContent} from "./modal-container.class";
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-modal-container',
    template: `
    @if (content != undefined) {
      <ng-template #refreshToken>
      <span>
          {{ "NOTIFICATION.ALERT.SESSION.TEXT" | translate }}
      </span>
        <br/>
        <div style="display: flex; flex-direction: column">
          <!-- PASSWORD INPUT -->
          <div class="form-floating form-floating-custom" style="display:flex;">
            <div class="form-floating-icon" style="align-self: flex-start">
              <i icon="password"></i>
            </div>
            <input type="{{fieldTextType ? 'text' : 'password'}}" class="form-control" id="input-password"
                   [(ngModel)]="password">
            <label for="input-password">{{ "AUTH.FIELD.PASSWORD.TITLE" | translate }}</label>
            <div class="form-floating-icon show-password"  style="margin-left: 410px;">
              <i icon="{{!fieldTextType ? 'pw-eye-on' : 'pw-eye-off'}}" (click)="fieldTextType = !fieldTextType"></i>
            </div>
          </div>
          @if(password == undefined || password.length == 0) {
            <div class="invalid-feedback" style="position: relative;">
              <span>{{ "AUTH.FIELD.PASSWORD.FORM.REQUIRED" | translate }}</span>
            </div>
          }
        </div>
      </ng-template>
      <div class="modal-header">
        <h4 class="modal-title">{{ content.title | translate }}</h4>
      </div>
      <div class="modal-body" style="min-height: 100px !important">
        @if (content.content != undefined) {
          <p style="white-space: pre-line;">{{ asString(content.content) | translate }}</p>
        }
        @if (content.templateRef != undefined && content.templateRef == 'refreshToken') {
          <ng-container *ngTemplateOutlet="refreshToken"></ng-container>
        }
      </div>
      <div class="modal-footer border-top-0">
        <button type="button" class="btn text-secondary {{content.submitClass || 'btn-outline-light'}}"
                (click)="onSubmit()">{{ (content.submitTitle || 'NOTIFICATION.ALERT.BUTTON.OK') | translate }}
        </button>
        <button type="button" class="btn text-secondary {{content.closeClass || 'btn-outline-danger'}}"
                (click)="onClose()">{{ (content.closeTitle || 'NOTIFICATION.ALERT.BUTTON.CANCEL') | translate }}
        </button>
      </div>
    }
  `,
    host: {},
    standalone: false
})
export class ModalContainerComponent {
  fieldTextType: boolean = false;
  public content?: ModalContainerContent;
  modal: NgbActiveModal;

  password: string = '';

  constructor(modal: NgbActiveModal) {
    this.modal = modal;
  }

  async onClose(): Promise<any> {
    const closeResult = await this.content?.close();
    this.modal.close(closeResult);
  }

  async onSubmit(): Promise<any> {
    const submitResult = await this.content?.submit();
    switch (this.content?.templateRef) {
      case 'refreshToken':
        this.modal.close(this.password);
        return;
      default:
        this.modal.close(submitResult);
    }
  }

  asString(val: any): string {
    return val as string;
  }

  protected readonly ElementRef = ElementRef;
}
