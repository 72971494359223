import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {
  catchError,
  firstValueFrom,
  Subject,
  switchMap
} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {RegisterUserDto} from "../../../shared/entities/user/RegisterUserDto";
import {UserLoginResponseDto} from "../../../shared/entities/user/UserLoginResponseDto";
import {CESService} from "../ces.service";
import {sha256} from "js-sha256";
import {UserSessionInfoDto} from "../../../shared/entities/user/UserSessionInfoDto";
import {jwtDecode} from "jwt-decode";
import {UserResetPasswordDto} from "../../../shared/entities/user/UserResetPasswordDto";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {LoginCdm} from "../../../shared/util/change-detector/models/login.cdm";
import {CookieService} from "../browser-helper-service/cookie.service";
import {LocalStorageSchemaService} from "../browser-helper-service/local-storage-schema.service";
import {NodeService} from "../api-service/sub-services/node.service";
import {Logger} from "../../../shared/util/logger";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends CESService {
  $loginOrLogout: Subject<string> = new Subject<string>();

  constructor(
    router: Router,
    httpClient: HttpClient,
    notification: ToastService,
    private nodeService: NodeService,
    private cookieService: CookieService) {
    super(router, httpClient, notification);
  }

  /**
   * Performs the register
   * @param system system-id
   * @param username username of user
   * @param firstname firstname of user
   * @param lastname lastname of user
   * @param email email of user
   */
  async register(system: string, username: string, firstname: string, lastname: string, email: string) {
    const newUser: RegisterUserDto = new RegisterUserDto(system, new LocalStorageSchemaService().fromLocalStorage('lang').plain, username, firstname, lastname, email);
    const result = await firstValueFrom(this.httpClient.post<UserLoginResponseDto>(await this.nodeService.buildUrl(system, '/users/registration'), newUser, {observe: 'response'}));
    if (result.ok) {
      await this.router.navigate(["/user/register/thankyou"]);
    }
  }

  /**
   * Performs the auth
   * @param dto Login
   */
  async loginDto(dto: LoginCdm): Promise<boolean> {
    return this.login(dto.system, dto.user, dto.password);
  }

  /**
   * Performs the auth
   * @param system system-id
   * @param username username of user
   * @param password password of user
   */
  async login(system: string, username: string, password: string): Promise<boolean> {
    this.system = system;
    const response = await firstValueFrom(this.httpClient.post<UserLoginResponseDto>(await this.nodeService.buildUrl(system, '/auth/login'),
      {
        'systemId': system,
        'userPassword': sha256(password),
        'username': username
      }));
    if (response == null) {
      return Promise.resolve(false);
    }
    this.$loginOrLogout.next('login');
    this.cookieService.setCookie(CookieService.SYSTEM, system);
    this.cookieService.setAuthCookie(response.token);
    this.session = new UserSessionInfoDto(
      response.changePassword,
      (response.userUuid || (jwtDecode(response.token) as {
        exp: number,
        system_uuid: string,
        user_uuid: string
      }).user_uuid) || '',
      response.roleId,
      'webclient',
      username,
      false,
      0,
      false,
    );
    return Promise.resolve(true);
  }

  /**
   * Performs change the current password
   * @param password old password of user
   * @param newPassword new password
   * @param confirmPassword confirm of new password
   */
  async changePassword(password: string, newPassword: string, confirmPassword: string): Promise<boolean> {
    if (newPassword != confirmPassword) {
      return false;
    }
    return (await firstValueFrom(this.httpClient.patch<any>(await this.nodeService.buildUrl(undefined, '/users/changePassword'),
      {newPassword: sha256(newPassword), currentPassword: sha256(password)},
      {observe: 'response'})))!.ok;
  }

  /**
   * Logout the user
   */
  async logout(): Promise<boolean> {
    const logout: boolean = (await firstValueFrom(this.httpClient.delete<any>(await this.nodeService.buildUrl(undefined, '/auth/logout'), {observe: 'response'}))).ok;
    this.cookieService.deleteCookie(CookieService.TOKEN);
    this.cookieService.deleteCookie(CookieService.EXPIRE_AT);
    this.cookieService.deleteCookie(CookieService.SYSTEM);
    this.$loginOrLogout.next('logout');
    await this.router.navigate(['user/logout'])
    return logout;
  }

  /**
   * Reset password
   * @param system system name
   * @param user user name
   */
  async resetPassword(system: string, user: string) {
    const userResetPasswordDto: UserResetPasswordDto = new UserResetPasswordDto(system, user);
    const url: string = await this.nodeService.buildUrl(system, '/users/passwordReset');
    if (url == '') {
      return false;
    }
    return (await firstValueFrom(this.httpClient.post<any>(url, userResetPasswordDto, {observe: 'response'}))).ok;
  }

  /**
   * Reset password
   * @param force forces the BE to grant a new token
   */
  async refreshToken(force?: boolean) {
    return this.httpClient.get<any>(
      await this.nodeService.buildUrl(undefined, `/auth/refreshToken${force ? '?force' : ''}`), {observe: 'response'});
  }

  isValidSession(): boolean {
    if (this.cookieService.getCookie(CookieService.TOKEN) == null) {
      return false;
    }
    if (this.cookieService.getCookie(CookieService.EXPIRE_AT) == null) {
      return false;
    }
    const expires: number = Date.parse(this.cookieService.getCookie(CookieService.EXPIRE_AT)!);
    return expires > Date.now();
  }

  async relogin(password: string): Promise<boolean> {
    return await this.login(this.system!, this.session!.username, password);
  }
}

